import './Documents.css';
import { AppHeader, CercleDocument, FileImport, TextEditor, ConfirmLeave } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { registerFiche, removeFiche } from '../../reducers/src/ficheSlice';
import { InteractWithAPI } from '../../components/src/ReponseIA/InteractWithAPI';
import { useState } from 'react';
import { registerDocument, removeDocument } from '../../reducers/src/documentSlice';
import { registerEditorValue, removeEditorValue } from '../../reducers/src/editorSlice';
import { useNavigate } from 'react-router-dom';

function Documents() {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state.user)
  const editorState = useSelector((state) => state.editor)
  const ficheState = useSelector((state) => state.fiche)
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);

  const navigate = useNavigate()


  const documentState = useSelector((state) => state.document)
  const [headerState, setHeaderState] = useState(0)
  const [alert, setAlert] = useState("")

  const dispatch = useDispatch()

  const handleBeforeUnload = (event) => {
    if (documentState.value !== "") {

      event.preventDefault();
      event.returnValue = "Êtes-vous sûr de vouloir quitter ? Votre travail sera perdu !";
      return "Êtes-vous sûr de vouloir quitter ? Votre travail sera perdu !";
    }
  };

  // Attach the event listener
  window.addEventListener("beforeunload", handleBeforeUnload);

  // Cleanup when the component unmounts
  window.addEventListener("unload", () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
  });


  const handleFiche = async () => {
    if (documentState.type !== "pdf") {
      dispatch(registerDocument({ document: editorState.value }))
    }
    const wordLength = documentState.value.trim().split(/\s+/).length;
    if (wordLength > 1000) {
      setAlert("Le document doit avoir moins de 1000 mots pour effectuer cette action");
      setTimeout(() => {

        setAlert("")
      }, 2000)
      return;
    }
    setLoading(true)
    try {

      const data = await InteractWithAPI('fiche', editorState.value, user.langue, user.age, user.passions, user.contentPreference, user.examplePreference);
      dispatch(registerFiche({ fiche: data }));
      dispatch(registerEditorValue(data))
      setHeaderState(1)

      setLoading(false)

    } catch (error) {

      setLoading(false)
      console.error('Erreur lors de l\'évaluation de la réponse:', error);
    }
  };

  const handleHeaderState = (selected) => {

    setHeaderState(selected)
    if (selected === 0 && documentState.value !== editorState.value) {
      dispatch(registerFiche({ fiche: editorState.value }))
    } else if (selected === 1 && ficheState.value !== editorState.value) {
      if (documentState.type !== "pdf") {
        dispatch(registerDocument({ document: editorState.value }))
      }
    }
    dispatch(registerEditorValue(selected === 0 ? documentState.value : ficheState.value))

  }

  const handleHomeButtonClick = () => {
    if (documentState.status !== "idle") {

      setShowConfirm(true);
    }
    else {
      navigate('/')
    }
  };

  const handleConfirmLeave = () => {
    setShowConfirm(false);
    navigate('/')
  };

  const handleCancelLeave = () => {
    setShowConfirm(false);
    setShowConfirmReset(false);
  };

  const handleReset = () => {
    setShowConfirmReset(true);
    return;
  }

  const handleConfirmReset = () => {
    dispatch(removeFiche());
    dispatch(removeDocument());
    dispatch(removeEditorValue());
    setShowConfirmReset(false);
  }

  return (

    <div className="documents-main-container">
      {alert !== "" && (
        <div

          style={{
            position: "absolute",
            bottom: "0px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "100",
            textAlign: "center",
          }}
          className="alert"
        >
          {alert}
        </div>
      )}

      <AppHeader showSearch={false} onHomeClick={() => handleHomeButtonClick()} />
      <FileImport handleHeaderState={handleHeaderState} headerState={headerState} />
      <div style={sonDiv} >
        <CercleDocument handleReset={handleReset} handleFiche={handleFiche} loading={loading} />
      </div>


      {showConfirm && (
        <ConfirmLeave
          message="Etes-vous sûr de vouloir quitter ? Votre travail sera perdu."
          onConfirm={handleConfirmLeave}
          onCancel={handleCancelLeave}
        />
      )}
      {showConfirmReset && (
        <ConfirmLeave
          confirmText='Nouveau document'
          message="Etes-vous sûr de vouloir changer le document ? Votre travail sera perdu."
          onConfirm={handleConfirmReset}
          onCancel={handleCancelLeave}
          confirmButtonWidth='200px'
        />
      )}
    </div>
  );
}

export { Documents };

const sonDiv = {
  position: "absolute",
  backgroundColor: "transparent",
  bottom: 0,
  left: 0,
  padding: "10px",
  height: "55px"
}