import { Routes, Route, Navigate } from 'react-router-dom';
import { Home, SignupPage, Quiz, Login, Documents, ProfilePage, ChoixQuiz, ReExercice } from "./pages";
import { useDispatch } from "react-redux";
import { loginUser } from './reducers/src/userSlice';
import React, { useState, useEffect } from "react"
import logo from './assets/img/logo.png';


const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem("user");



  const isAuthenticated = user !== null;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const App = () => {
  const dispatch = useDispatch()
  const user = localStorage.getItem("user")

  const [isMobile, setIsMobile] = useState(false);

  // Check if the user is on a mobile device
  useEffect(() => {
    const checkIfMobile = () => {
      const mobileWidthThreshold = 768; // Width in pixels below which it is considered mobile
      setIsMobile(window.innerWidth < mobileWidthThreshold);
    };

    // Check on initial load
    checkIfMobile();

    // Add event listener to update on window resize
    window.addEventListener('resize', checkIfMobile);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  if (user) {
    dispatch(loginUser(JSON.parse(user)))

  }

  if (isMobile) {
    return (
      <div style={{ height: "100vh", alignContent: "center", background: "linear-gradient(to right, #AB0B44, #FF4D4D)", flexDirection: "column", justifyContent: "center", display: "flex", alignItems: "center" }} >
        <div style={{ backgroundColor: "transparent", width: '200px', height: '150px', overflow: 'hidden' }}>
          <img
            src={logo}
            alt="description"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </div>
        <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px', color: "white" }}>
          Cette application n'est pas utilisable sur un appareil mobile. Veuillez utiliser un ordinateur.
        </div>
      </div>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/documents" element={
          <ProtectedRoute>
            <Documents />
          </ProtectedRoute>
        } />
        <Route path="/exercices-similaires" element={
          <ProtectedRoute>
            <ReExercice />
          </ProtectedRoute>
        } />
        <Route path="/test-connaissance" element={
          <ProtectedRoute>
            <Quiz />
          </ProtectedRoute>} />
        <Route path="/choixQuiz" element={
          <ProtectedRoute>
            <ChoixQuiz />
          </ProtectedRoute>
        } />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/profile" element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
        />
      </Routes>
    </>)

}

export default App