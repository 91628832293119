import React, { useState } from 'react';
import mammoth from 'mammoth';
import { TextEditor } from '../DocumentManipulation/QuilEditor';
import "./FileImport.css"
import { useDispatch, useSelector } from 'react-redux';
import { Donwload } from '../../../assets/svg/src/download';
import { registerDocument } from '../../../reducers/src/documentSlice';
import pdfToText from 'react-pdftotext'
import { registerEditorValue } from '../../../reducers/src/editorSlice';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';




function FileImport({ handleHeaderState, headerState }) {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const dispatch = useDispatch();
    const [showImportConfirm, setShowImportConfirm] = useState(false);

    const documentState = useSelector((state) => state.document)


    function onDocumentLoadSuccess({ numPages }) {
        dispatch(registerDocument({ pdfFile: { ...documentState.pdfFile, numPages: numPages } }))
    }

    const htmlToSlateFunction = async (event) => {
        const file = event.target.files[0];

        if (file) {

            try {
                let htmlString = null;
                let type = "";

                if (file.name.endsWith('.docx')) {
                    type = "docx"
                    // Handling .docx files
                    const arrayBuffer = await file.arrayBuffer();
                    const result = await mammoth.convertToHtml({ arrayBuffer });
                    htmlString = result.value; // Correctly accessing the HTML string from .docx
                } else if (file.name.endsWith('.pdf')) {
                    type = "pdf"
                    pdfToText(file)
                        .then(text => {
                            dispatch(registerDocument({ document: text }))
                        })

                }

                dispatch(registerDocument({
                    document: htmlString,
                    type: type,
                    pdfFile: type === "pdf" ? {
                        file: URL.createObjectURL(file),
                        numPages: numPages,
                        pageNumber: pageNumber
                    } : null,
                }));  // Trigger the async thunk on component mount

                dispatch(registerEditorValue(htmlString))
            } catch (error) {
                console.error('Error processing the file:', error);
            }
        }
    };



    const textStyle = {
        fontFamily: '"Sitka-Bold", Helvetica',
        color: "#A1A1A1",
        fontWeight: 500,
        fontSize: 20
    }

    const logoStyle = {
        width: 100,
        height: 100,
        padding: 5
    }

    const importFile = () => {
        setShowImportConfirm(false);
        document.getElementById('file-input').click();
    }

    const loadEmptyDocument = () => {
        setShowImportConfirm(false);
        dispatch(registerDocument({
            document: "",
            type: "docx",

        }));  // Trigger the async thunk on component mount

        dispatch(registerEditorValue(""))
    }

    return (
        documentState.status !== "idle" ?
            <TextEditor onPdfLoadSuccess={onDocumentLoadSuccess} handleHeaderState={handleHeaderState} headerState={headerState} />
            : <div className='page-main-container'>
                {
                    showImportConfirm && <ImportConfirm onEmptyPageClick={loadEmptyDocument} import={importFile} />
                }
                <div className='import-file' onClick={() => setShowImportConfirm(!showImportConfirm)}>
                    <input
                        type="file"
                        accept={[".docx", ".pdf"]}
                        id="file-input"
                        style={{ display: 'none' }}
                        onChange={htmlToSlateFunction}
                    />
                    <div className="import-file-content">
                        <Donwload style={logoStyle} fillColor="#A1A1A1" />
                    </div>
                    <div style={textStyle} >
                        {"importez un doccument"}
                    </div>
                    <div style={textStyle}>
                        {"[pdf-word-txt]"}
                    </div>
                </div>


                <div className="ai-main-container"></div>
            </div>

    );
}

function ImportConfirm(props) {

    return <div
        style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 1000,
        }}
    >
        <div
            style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
                flexDirection: "column",
                display: "flex",
                textAlign: "center",
            }}
        >
            <p>{"Importer :"}</p>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: '10px' }}>

                <PrimaryButton onClick={props.onEmptyPageClick} width='150px' style={{ marginRight: "10px" }} text={"Page Blanche"} textColor="white" background="linear-gradient(to right, #AB0B44, #FF4D4D)" />

                <PrimaryButton onClick={props.import} width='150px' text="Document" textColor="white" background="linear-gradient(to right, #AB0B44, #FF4D4D)" />
            </div>
        </div>
    </div>;
}

export { FileImport }

