import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from 'react-quill';
import { registerEditorValue } from "../../../reducers/src/editorSlice";


export const DocumentEditor = ({ setSelectedText, setOldSelectedText, quillRef, setIsSpeaking }) => {

    const editorState = useSelector((state) => state.editor);

    const dispatch = useDispatch();



    const handleSelectionChange = (text) => {
        const editor = quillRef.current.getEditor(); // Access Quill instance
        const range = editor.getSelection(); // Get the selection range

        if (range && range.length > 0) {
            const selectedText = editor.getText(range.index, range.length);
            setSelectedText(selectedText);
            setOldSelectedText(selectedText);
            setIsSpeaking('pause'); //pour rénitialiser pause à chaque lecture
        } else {
            setSelectedText('');
        }
    };

    const handleDocumentChange = () => {
        if (!quillRef.current) return;
        const editor = quillRef.current.getEditor();
        const html = editor.root.innerHTML;

        dispatch(registerEditorValue(html))
    }
    return (
        <ReactQuill
            className='editor-container'
            ref={quillRef}
            value={editorState.value}
            onChangeSelection={handleSelectionChange}
            onChange={handleDocumentChange}
            modules={{ toolbar: "#toolbar" }} // Disable built-in toolbar
        />
    )
}