import React, { useState } from 'react';
import './reponseIA.css';
import back from "../../../assets/img/ReponseIA/back.svg";
import triangle from "../../../assets/img/ReponseIA/triangle.svg";
import logo from "../../../assets/img/ReponseIA/logo.png";
import TextToSpeech from './TextToSpeech';
import { CheckMarkSvg, CopySvg } from '../../../assets/svg';

function ReponseIAText({ phraseSelec, action, showResponse, response, loading, onBackClick }) {
  return (
    !showResponse ? (
      <div className="ai-main-container"></div>
    ) : (
      <div className="ai-main-container">
        <div className="phrase-container">
          <div className="text-container">
            <p className="text-phrase">{phraseSelec}</p>
          </div>
          <img className="back-icon" alt="back" src={back} onClick={() => onBackClick()} />
        </div>
        <div className="response-container">
          {action === 'lire' ? (
            <TextToSpeech text={phraseSelec} language={'french'} />
          ) : (
            <div className="text-response">
              {loading ? 'Loading...' : <div dangerouslySetInnerHTML={{ __html: response }} />}
            </div>
          )}
          {loading ? null : <CopyButton text={response} />}
        </div>
        <img className="triangle" alt="Vector" src={triangle} />
        <img className="logo" alt="logo" src={logo} />
      </div>
    )
  );

}

export { ReponseIAText };

const CopyButton = ({ text }) => {

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");

    const copiedText = doc.body.innerText || ""; // Extracts visible text only

    navigator.clipboard.writeText(copiedText).then(() => {
      setIsCopied(true); // Set copied state to true

      // Automatically revert back to the copy icon after 2 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };
  return (
    <div
      style={{
        position: "absolute",
        bottom: 5,
        right: 5,
        cursor: "pointer"
      }}
      title={isCopied ? "Le texte est copié" : null}
      onClick={handleCopy}>
      {
        isCopied ?
          <CheckMarkSvg color='white' />
          : <CopySvg color="white" />
      }
    </div>
  )
}
