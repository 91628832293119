/**
 * Ma documentation: ce composant a pour role d'affichier une question Ouverte, et sa correction si la reponse est fausse
 */


import React, { useState, useEffect, useCallback } from 'react';
import './questionD.css';
import { CallAPI } from './CallAPI'; 
import CorrectionComponent from './CorrectionComponent'; 
import { useSelector } from 'react-redux';

export const OuverteSim = ({ type, textQuiz }) => {
    const user = useSelector((state) => state.user);
  const [question, setQuestion] = useState('');
  const [userResponse, setUserResponse] = useState('');
  const [correction, setCorrection] = useState('');
  const [tablequestions, setTableQ] = useState([]);
  const [isNextQuestionAvailable, setIsNextQuestionAvailable] = useState(false);
  const [showCorrection, setShowCorrection] = useState(false); 
  
  // Fonction pour générer la première question
  const fetchQuestion = useCallback(async () => {
    try {
        const Data = {
            userResponses: null,
            tablequestions,
          };
        const data = await CallAPI(type, Data, textQuiz, user, 'similar');
      if (data.isQuestion) {
        setTableQ((prevCorrections) => [...prevCorrections, data.content]);
        setQuestion(data.content); 
        setCorrection(''); 
        setIsNextQuestionAvailable(false); 
        setShowCorrection(false); }
    } catch (error) {
      console.error('Erreur lors du chargement de la question:', error);
    }
  }, [type, textQuiz]);

  useEffect(() => {
    fetchQuestion(); // Générer la première question au montage du composant
  }, [fetchQuestion]);

  // Fonction pour gérer la soumission de la réponse
  const handleResponseSubmit = async () => {
    if (!userResponse.trim()) return; 
    try {
        const Data = {
            userResponses: userResponse,
            tablequestions,
          };
        const data = await CallAPI(type, Data, textQuiz, user, 'similar');
      if (!data.isQuestion) {
        setCorrection(data.content); // Mettre à jour avec la correction si la réponse est incorrecte
        setIsNextQuestionAvailable(true); 
        setShowCorrection(true); // Afficher le composant de correction
      } else {
        setQuestion(data.content); // Charger la nouvelle question si la réponse est correcte
        setCorrection(''); 
        setUserResponse(''); 
        setIsNextQuestionAvailable(false); 
        setShowCorrection(false); 
      }
    } catch (error) {
      console.error('Erreur lors de l\'évaluation de la réponse:', error);
    }
  };

  // Fonction pour passer à la question suivante
  const handleNextQuestion = () => {
    setUserResponse(''); 
    fetchQuestion(); 
  };

  return (
    <div className='quiz-initDiv'>
      {showCorrection ? (
        <CorrectionComponent
          correction={correction}
          onNext={handleNextQuestion}
        />
      ) : (
        <>
          <div className="quiz-questionD">
            <div className="quiz-questionP">
              {question} 
            </div>
          </div>
          <div className="quiz-responseA">
                
          <textarea
                className="quiz-response"
                placeholder="Écrire une réponse..."
                value={userResponse}
                onChange={(e) => setUserResponse(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); 
                    handleResponseSubmit();
                  }
                }}
                rows={3} 
                style={{ width: '100%', resize: 'none', overflowY: 'auto' }} 
              />

          </div>
        </>
      )}
    </div>
  );
};