import React, { useState, useEffect, useCallback } from 'react';
import './questionD.css';
import { CallAPI } from './CallAPI';
import { useSelector } from 'react-redux';

export const QuestionQCM = ({ type }) => {
  const user = useSelector((state) => state.user);
  const documentState = useSelector((state) => state.document);
  const textQuiz = documentState.value;

  const [question, setQuestion] = useState('');
  const [choices, setChoices] = useState([]);
  const [selectedChoice, setSelectedChoice] = useState('');
  const [corrections, setCorrections] = useState([]); // Pour stocker les corrections
  const [userResponses, setUserResponses] = useState([]); // Pour stocker les réponses de l'utilisateur
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [finalResults, setFinalResults] = useState(null); // Pour stocker les résultats finaux
  const [currentCorrectionIndex, setCurrentCorrectionIndex] = useState(0); // Index de correction actuelle
  const totalQuestions = 10; // Dynamique, mis à jour via API
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Pour suivre la question actuelle

  // Fonction pour générer la question et éventuellement récupérer le nombre de questions
  const fetchQuestion = useCallback(async () => {
    setIsLoading(true); // Activer l'état de chargement
    try {
      const data = await CallAPI(type, null, textQuiz, user, 'quiz'); // Appel de l'API pour générer une nouvelle question
      if (data.isQuestion) {
        const { question, options } = parseQCM(data.content); // Extraction des choix et de la question
        setQuestion(question);
        setChoices(options);
        setSelectedChoice('');
      } else {
        setIsQuizFinished(true); // Terminer le quiz si aucune question n'est retournée
      }
    } catch (error) {
      console.error('Erreur lors du chargement de la question:', error);
    } finally {
      setIsLoading(false); // Désactiver l'état de chargement
    }
  }, [type, textQuiz, user]);

  useEffect(() => {
    fetchQuestion(); // Générer la première question au montage du composant
  }, [fetchQuestion]);

  // Fonction pour analyser les questions et les choix à partir du texte
  const parseQCM = (text) => {
    const lines = text.split('\n').filter((line) => line.trim() !== ''); // Filtrer les lignes vides
    const options = lines.slice(-4).map((line) => { // On prend seulement les 4 dernières lignes
      const optionParts = line.split(') '); // Séparer par ') ' pour enlever les lettres (A), B), etc.)
      return optionParts.length > 1 ? optionParts[1].trim() : optionParts[0].trim(); // Retourne l'option sans lettre
    });
    const question = lines.slice(0, -4).join('\n').trim(); // Joindre toutes les lignes sauf les dernières 4 pour la question
    return { question, options };
  };




  // Fonction pour gérer la soumission de la réponse
  const handleResponseSubmit = async (choice) => {
    setSelectedChoice(choice);
    setUserResponses((prev) => [...prev, choice]); // Stocker la réponse de l'utilisateur

    try {
      const data = await CallAPI(type, choice, textQuiz, user, 'quiz');
      if (!data.isQuestion) {
        // Stocker la correction
        setCorrections((prevCorrections) => [...prevCorrections, data.content]);
        // Passer à la question suivante ou évaluer les réponses
        if (currentQuestionIndex + 1 < totalQuestions) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          fetchQuestion(); // Charger la question suivante
        } else {
          await evaluateResponses(); // Fin du quiz, évaluer les réponses
        }
      }
    } catch (error) {
      console.error('Erreur lors de l\'évaluation de la réponse:', error);
    }
  };

  // Évaluer les réponses de l'utilisateur
  const evaluateResponses = async () => {
    try {
      const evaluationData = {
        responses: userResponses,
        corrections: corrections, // Les corrections déjà collectées
      };
      const result = await CallAPI(type, evaluationData, textQuiz, user, 'evaluer');
      setFinalResults(result); // Stocker les résultats
      setIsQuizFinished(true); // Finir le quiz
    } catch (error) {
      console.error('Erreur lors de l\'évaluation des réponses:', error);
    }
  };

  // Fonction pour passer à la correction suivante
  const handleNextCorrection = () => {
    setCurrentCorrectionIndex((prevIndex) => Math.min(prevIndex + 1, corrections.length - 1));
  };

  // Fonction pour revenir à la correction précédente
  const handlePreviousCorrection = () => {
    setCurrentCorrectionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <div className='quiz-initDiv'>
      {isQuizFinished ? (
        <div className="quiz-results">
          <h2>Quiz terminé !</h2>
          {finalResults && (
            <div className="final-results">
              <p>Score: Vous avez obtenu {finalResults.score}\10.</p>
              <div dangerouslySetInnerHTML={{ __html: finalResults.content }} />
            </div>
          )}
          <div className="quiz-responses">
            {corrections.length > 0 && (
              <div className="quiz-response">
                <p>Correction pour la question {currentCorrectionIndex + 1}: {corrections[currentCorrectionIndex]}</p>
              </div>
            )}
          </div>
          <div className="correction-navigation">
            <button className="buttonCorrection" onClick={handlePreviousCorrection} disabled={currentCorrectionIndex === 0}>
              Précédent
            </button>
            <button className="buttonCorrection" onClick={handleNextCorrection} disabled={currentCorrectionIndex === corrections.length - 1}>
              Suivant
            </button>
          </div>
        </div>
      ) : (
        <div className="quiz-questionD">
          <div className="quiz-questionP">
            {isLoading ? (
              <div className="loader">Chargement...</div> // Affichage du loader pendant le chargement
            ) : (
              <>
                <p>Question {currentQuestionIndex + 1} sur {totalQuestions}</p>
                <p>{question}</p>
                <div className="quiz-responseQCM">
                  {choices.map((choice, index) => (
                    <button
                      key={index}
                      className={`buttonQCM ${selectedChoice === choice ? 'selected' : ''}`}
                      onClick={() => handleResponseSubmit(choice)}
                    >
                      {choice}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
