import { useState } from "react";
import "./tools.css";

import corriger from "../../../assets/img/IconTools/corriger.svg";
import definition from "../../../assets/img/IconTools/definition.svg";
import dictionnaire from "../../../assets/img/IconTools/dictionnaire.svg";
import explication from "../../../assets/img/IconTools/explication.svg";
import lecture from "../../../assets/img/IconTools/lecture.svg";
import resume from "../../../assets/img/IconTools/resume.svg";
import traduire from "../../../assets/img/IconTools/traduire.svg";

export const Tool = ({ handleActionClick }) => {
    const [langueTr, setLangueTr] = useState("en"); 
    const [showSelect, setShowSelect] = useState(false); 

   
    const handleLanguageChange = (event) => {
        const newLang = event.target.value;
        setLangueTr(newLang);
        setShowSelect(false); 
        handleActionClick("translate", newLang);
    };

    return (
        <div className="tools">
            <div className="tool">
                <img className="action" title="Expliquer" alt="explication" src={explication} onClick={() => handleActionClick("explain")} />
                <span className="tooltip">Expliquer</span>
            </div>
            <div className="tool">
                <img className="action" title="Reformuler" alt="reformuler" src={dictionnaire} onClick={() => handleActionClick("reformuler")} />
                <span className="tooltip">Reformuler</span>
            </div>
            <div className="tool">
                <img className="action" title="Dictionnaire" alt="dictionnaire" src={definition} onClick={() => handleActionClick("dictionnaire")} />
                <span className="tooltip">Dictionnaire</span>
            </div>
            <div className="tool">
                <img className="action" title="Résumer" alt="resume" src={resume} onClick={() => handleActionClick("resume")} />
                <span className="tooltip">Résumer</span>
            </div>
            <div className="tool">
                <img className="action" title="Corriger" alt="corriger" src={corriger} onClick={() => handleActionClick("correct")} />
                <span className="tooltip">Corriger</span>
            </div>
            <div className="tool">
                <img className="action" title="Lire" alt="lecture" src={lecture} onClick={() => handleActionClick("lire")} />
                <span className="tooltip">Lire</span>
            </div>
            <div className="tool">
                <div className="tool-container" onClick={() => setShowSelect(!showSelect)}>
                    <img className="action" title="Traduire" alt="traduire" src={traduire} />
                </div>
                {showSelect && (
                    <select className="language-select" value={langueTr} onChange={handleLanguageChange}>
                        <option value="Français">Français</option>
                        <option value="Anglais">Anglais</option>
                        <option value="Arabe">Arabe</option>
                        <option value="Espagnol">Espagnol</option>
                        <option value="Portugais">Portugais</option>
                        <option value="Italien">Italien</option>
                        <option value="Allemand">Allemand</option>
                        <option value="Chinois">Chinois (Mandarin)</option>
                        <option value="Russe">Russe</option>
                    </select>
                )}
                <span className="tooltip">Traduire</span>
            </div>
        </div>
    );
};
