import React from "react";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";

const ConfirmLeave = ({ message, onConfirm, onCancel, confirmText, showSecondButton, confirmButtonWidth }) => {
    if (showSecondButton === undefined) {
        showSecondButton = true;
    }
    return (
        <div
            style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "8px",
                    flexDirection: "column",
                    display: "flex",
                    textAlign: "center",
                }}
            >
                <p>{message}</p>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: '10px' }}>

                    <PrimaryButton onClick={onConfirm} style={{ marginRight: "10px" }} width={confirmButtonWidth ?? null} text={confirmText === undefined ? "Quitter" : confirmText} textColor="white" background="linear-gradient(to right, #AB0B44, #FF4D4D)" />

                    {showSecondButton && <PrimaryButton onClick={onCancel} text="Retour" showBorder={true} />}
                </div>
            </div>
        </div>
    );
};

export { ConfirmLeave };
